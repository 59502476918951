// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isTestsystem: true,
  modules: {
    news: true,
    epaper: true,
    showSubscriptionService: true,
  },
  identity: {
    server: 'https://localhost:4100',
    clientId: 'ivz_digital_news',
  },
  digitalnews: {
    frontendUrl: 'http://localhost:4220',
    apiUrl: 'https://localhost:4120/',
    apiPrefix: 'digital-news/web/',
    apiName: 'api_digital_news',
  },
  contentManagement: {
    frontendUrl: 'http://localhost:4240',
  },
  sitemaps: {
    content: null,
    news: null,
  },
  logging: {
    azureAppInsightsConnectionString: null,
  },
  consent: {
    enabled: false,
    id: null, // set to null to not include the consent manager
    // The names of the services the consent manager uses.
    // Can be found in the usercentrics backend
    // or by calling UC_UI.getServicesBaseInfo() and UC_UI.getTCFVendors() in the browser console
    // Note: Not all of these are used directly in the app, some are only used to generate the index.html
    //       For more info see generate-clients.build.ts#provideTransformConfiguration
    serviceNames: {
      trackingPixel: 'INFOnline',
      matomo: 'Matomo (self hosted)',
      revive: 'Revive Adserver',
      googleTagManager: 'Google Tag Manager',
      azureAppInsights: 'Azure',
      agma: 'Media-Micro-Census GmbH',
      uniten: 'uniten',
    },
  },
  trackingPixels: {
    domainServiceName: 'data-09d76f48f8.ivz-aktuell.de',
    publication: 'ivz',
    enabled: true,
  },
  matomo: {
    enabled: false,
    siteId: 2,
    domain: 'https://ivz-matomo-test.westeurope.cloudapp.azure.com',
    scriptUrl: '~/matomo.js',
    trackerUrl: '~/matomo.php',
    vendor: null,
    customDimensions: {
      loginStatus: 1,
    },
  },
  agma: {
    enabled: false,
    debug: true,
    code: 'zgm',
  },
  uniten: {
    enabled: false,
  },
  ads: {
    provider: 'preview', // preview | symplr | revive,
    // revive: {
    //   client: '18025a370bc5f9662cb610241b728884',
    //   serverUrl: 'https://adserver.ivz-medien.de/www/delivery/asyncjs.php',
    // },
    // symplr: {
    //   serverUrl: '',
    // }
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
